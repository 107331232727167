
import requestOfferConstants from "~/store/requestOffer/-constants";
import baseConstants from "~/store/base/-constants";

export default {
  name: 'TemplateLanding',
  data() {
    return {
      sections: []
    }
  },
  head() {
    const { generalConfig } = this.$store.state.base.meta;
    const { cdnBase } = generalConfig;
    const meta = [
      { vmid: 'title', name: 'title', content: this.meta.title },
      { vmid: 'description', name: 'description', content: this.meta.description },
      { vmid: 'og:description', name: 'og:description', property: 'og:description', content: this.meta.description },
      { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { vmid: 'twitter:description', name: 'twitter:description', content: this.meta.description },
    ]

    if (this.meta.image) {
      meta.push(
        { vmid: 'image', name: 'image', content: `${cdnBase}/${this.meta.image}` },
        { vmid: 'og:image', name: 'og:image', property: 'og:image', content: `${cdnBase}/${this.meta.image}` },
        { vmid: 'twitter:image', name: 'twitter:image', content: `${cdnBase}/${this.meta.image}` }
      )
    }
    return {
      title: generalConfig.baseTitle,
      titleTemplate: `${generalConfig.baseTitle} - ${this.meta.title}`,
      meta,
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.base.meta.generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    meta() {
      return this.$store.state.base.meta.landingConfig.meta
    },
    sectionsData() {
      return JSON.parse(JSON.stringify(this.$store.state.base.meta.landingConfig.sections))
    }
  },
  mounted() {
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  },
  created() {
    this.sortSections()
  },
  methods: {
    sortSections() {
      this.sections = this.sectionsData.sort((a, b) => a.order_no - b.order_no)
    }
  },
}
